#sidebar {
    &.active {
        .sidebar-wrapper {
            left: 0;
        }
    }
    &:not(.active) {
        & ~ #main {
            margin-left: 0;
        }
    }
}
.sidebar-wrapper {
    width: 260px;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: #fff;
    bottom: 0;
    border-right: 1px solid #eee;
    box-shadow: 0 0 10px #ececec;
    left: -260px;
    transition: left .6s ease-out;
    .sidebar-header {
        padding: 2rem 2rem 1rem;
        font-size: 2rem;
        font-weight: bold;

        img {
            height: 2.5rem;
        }
    }
    .sidebar-toggler.x {
        position: absolute;
        right: 0;
        top: .5rem;
        display:none;
    }

    .menu {
        padding-left: 0;
        margin-top: 2rem;

        .sidebar-title {
            padding: 1.5rem 1.8rem .5rem;
            font-size: .8rem;
            font-weight: 600;
            color: #888;
            text-transform: uppercase;
        }
        
        .sidebar-link {
            display: block;
            padding: .7rem 1.5rem;
            font-size: .85rem;
            display: flex;
            align-items: center;
            transition: all .5s;
            &:hover {
                span {
                    opacity: 1;
                }
            }
            & > span {
                color: #053382;
                font-weight: 500;
                margin-left: 1rem;
                opacity: 0.8;
            }
        }
        .sidebar-item {
            list-style: none;
            margin-top: .5rem;
            position: relative;
            &.has-sub {
                .sidebar-link:after {
                    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" style="fill:none;stroke:gray;stroke-width:1"></polyline></svg>');
                    position: absolute;
                    color: #ccc;
                    right: 15px;
                    top: 12px;
                    display:block;
                }
            }
            &.active {
                .sidebar-link {
                    background-color: #e8f3ff;
                }
                .sidebar-link:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    background-color: rgba(#2EAAF4,.5);
                    width: 5px
                }
            }
        }
        .submenu {
            list-style: none;
            max-height: 0;
            transition: max-height 0.5s ease-out;
            overflow: hidden;
            &.active {
                max-height: 500px;
                // transition: max-height 0.2s ease-in;
            }
            li {
                a {
                    padding: .7rem 3rem;
                    display: block;
                    color: #676767;
                    font-size: .75rem;
                    font-weight: 500;
                    letter-spacing: .5px;
                    transition: all .2s;
                    &:hover {
                        margin-left: .3rem;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .sidebar-wrapper {
        left: -270px;   
        .sidebar-toggler.x  {
            display:block;
        }
    }
}