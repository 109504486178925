@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap');

* {
    font-family: 'Raleway';
}
#app {
    position: relative;
}
#main {
    margin-left: 260px;
    background-color: #F7FAFF;
    min-height: 100vh;
    transition: margin-left .6s ease-out;
    .main-content {
        padding: 2rem 1.5rem;

        .page-title h3 {
            font-size: 2rem;
        }
        .text-subtitle {
            font-size: .9rem;
        }
    }
}
.breadcrumb-header {
    .breadcrumb {
        justify-content: flex-end;
    }
}
.alert.alert-dismissible .close:focus, .custom-switch .custom-control-label:focus, .dropdown .dropdown-toggle:focus, .dropleft .dropdown-toggle:focus, .dropright .dropdown-toggle:focus, .dropup .dropdown-toggle:focus, .list-group button.list-group-item:focus, .modal .modal-content .modal-header .close:active, .modal .modal-content .modal-header .close:focus, .modal .modal-content .modal-header .close:hover, .navbar-components-wrapper .navbar .navbar-container.navbar-dark .navbar-toggler:focus, .navbar-components-wrapper .navbar .navbar-container.navbar-light .navbar-toggler:focus, .toast .toast-header .close:active, .toast .toast-header .close:focus, a:focus {
    outline: 0;
}
section {
    .section-title {
        &:after {
            width: 70px;
            height: 2px;
            content: '';
            margin: .6rem 0 1rem;
            background-color: map-get($theme-colors, 'primary');
            display:block;
        }
    }
}
a {
    text-decoration: none;
    outline: 0;
}
button {
    outline: 0;
}
.feather,i {
    font-size: 14px;
    height: 1rem
}
footer {
    padding: 1rem 1.5rem;
}
@media screen and (max-width: 767px) {
    #main {
        margin-left: 0;   
    }
    .breadcrumb-header {
        .breadcrumb {
            justify-content: flex-start;
            padding: 0;
            font-size: .8rem;
        }
    }
}