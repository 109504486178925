.table td, .table thead th {
    vertical-align: middle;
}

.table thead th {
    border-bottom: 2px solid #475F7B;
}

.dataTable-table {
    @extend .table
}

.dataTable-container {
    overflow-x:auto
}