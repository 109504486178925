@import './bootstrap.scss';
@charset "UTF-8";
/**
 * Voler
 *
 * Voler is a clean & modern HTML5 admin template based on Bootstrap 5
 * Voler will make it easier for you to create your own admin interface.
 *
 * @package	Voler
 * @version	0.1
 * @author 	Ahmad Saugi
 * @url 	https://github.com/zuramai/voler
 * 
 */
/**
 * TABLE OF CONTENTS
 *
 * 1. Variables
 * 2. Bootstrap Override
 * 	  2.1 Alert
 * 	  2.2 Avatar
 * 	  2.3 Badge
 * 	  2.4 Buttons
 * 	  2.5 Breadcrumb
 * 	  2.6 Carousel
 * 	  2.7 Divider
 * 	  2.8 Dropdowns
 * 	  2.9 Forms
 * 	  2.10 Modal
 * 	  2.11 Sidebar
 * 	  2.12 Card
 * 	  2.13 Navs
 * 	  2.14 Navbar
 * 	  2.15 Pagination
 * 	  2.16 Table
 * 	  2.17 Progress
 * 4. Pages
 * 	  4.1 Auth
 * 	  4.2 Error
 * 5. Utilities
 */
 


 /** 1. Variables  **/
@import "./variables";
@import "./main";

/** 2.1 Alert  **/
@import "./components/alert";

/** 2.2 Avatar **/
@import "./components/avatar";

/** 2.3 Badge **/
@import "./components/badge";

/** 2.4 Buttons **/
@import "./components/buttons";

/** 2.5 Breadcrumb **/
@import "./components/breadcrumb";

/** 2.6 Carousel **/
@import "./components/carousel";

/** 2.7 Divider **/
@import "./components/divider";

/** 2.8 Dropdowns **/
@import "./components/dropdowns";

/** 2.9 Forms **/
@import "./components/forms";

/** 2.10 Modal **/
@import "./components/modal";

/** 2.11 Sidebar **/
@import "./components/sidebar";

/** 2.12 Card **/
@import "./components/card";

/** 2.13 Navs **/
@import "./components/navs";

/** 2.14 Navbar **/
@import "./components/navbar";

/** 2.15 Pagination **/
@import "./components/pagination";

/** 2.16 Table **/
@import "./components/table";

/** 2.17 Progress **/
@import "./components/progress";


/** 3.1 Chat **/
@import "./widgets/chat";

/** 3.1 Todo **/
@import "./widgets/todo";

/** 4.1 Auth Pages **/
@import "./pages/auth";

/** 4.1 Error Pages **/
@import "./pages/error";

/** 5. Utilities **/
@import "./utilities";
