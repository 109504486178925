#auth {
    background:$gray-600;
    min-height: 100vh;
    padding-top: 100px;
    background-image: url(../images/background/auth.jpg);

    .card {
        box-shadow: 0 5px 15px rgba(#246892, .6);
        border:none;
    }
}